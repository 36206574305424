<template>
  <b-page class="g-page-list">
    <!-- 条件查询 -->
 <div style="display: flex; align-items: flex-end; justify-content: space-between;">
      <el-form label-position="top" ref="searchForm" :model="searchData">
        <el-form-item label="" prop="state">
          <span>状态：</span>
          <!-- 进行中，已完成，超时完成,超时未完成 -->
          <el-select
            v-model="searchData.parent_id"
            placeholder="请选择"
            style="width: 200px;"
            @change="change"
          >
            <el-option
              label="进行中"
              value="0"
            ></el-option>

            <el-option
              label="已完成"
              value="442"
            ></el-option>

          </el-select>
        </el-form-item>
      </el-form>
      <!-- <div class="g-mb-15">
        <c-btn-search @search="onRefresh" @reset="onReset" ></c-btn-search>
      </div> -->
    </div>
    <!-- 表格 -->
    <el-table v-loading="loading" :data="list">
      <!-- <el-table-column label="类别" prop="special.parent.title"></el-table-column> -->
      <el-table-column label="标题" prop="special.title">
        <template slot-scope="{ row }">
          {{row.special.title}}
          <el-tag  type='danger' v-if="row.special.parent.id=='442'">已完成</el-tag>
          <el-tag effect="plain" v-else> {{ row.special.parent.title }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="发布人" prop="special.department.name" width="160">
        <template slot-scope="{ row }">
          {{row.special.department.name}}({{row.special.create_user.name}})
        </template>
      </el-table-column>
      <el-table-column label="发布时间" sortable prop="created_at" width="140"></el-table-column>
      <el-table-column label="最后更新时间" sortable prop="special.updated_at" width="140"></el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template slot-scope="{ row }">
          <c-btn-table :show="['info']" @info="onInfo(row)"></c-btn-table>
        </template>
      </el-table-column>
    </el-table>

    <b-pagination :page="page" @change="fetchList"></b-pagination>
  </b-page>
</template>

<script>
import { getSpecialReceivePage } from '@/api/special'
export default {
  data() {
    return {
      loading: false,
      searchData: {
        title: '',
        parent_id:""
      },
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      list: [],
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  watch: {
    '$route.path': {
      handler(val) {
        // my、attention、receive、create
        this.currentPageType = val.split('/').slice(-1)[0]
        this.fetchList()
      },
      immediate: true,
    },
  },
  methods: {
    change(){
      this.page.current=0;
       this.fetchList()
    },
    fetchList() {
      const params = {
        department_id: this.deptId,
        parent_id:this.searchData.parent_id||0,
        page: this.page.current,
      }
      this.loading = true
      getSpecialReceivePage(params)
        .then(res => {
          this.list = res.data
          // res.data.forEach(element => {
          //   if( element.special.department.id !== this.deptId ){
          //     this.list.push(element)
          //   }
          // });
          this.page.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onInfo(row) {
      this.$router.push({ path: '/special/detail', query: { id: row.special_id, task_id: row.id } })
    },
  },
}
</script>
